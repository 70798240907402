import React from 'react'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'

export default function SpecialtyFoodIngredients() {
  return (
    <UnauthenticatedLayout>
      <h1>Sourcing</h1>
    </UnauthenticatedLayout>
  )
}
